import React, { Component } from 'react';
import "./LandingPage.scss";
import { Linkedin, Instagram, Mail } from 'react-feather';

class LandingPage extends Component {
	render() { 
		return (
			<div class="landing-page__outer">
				<div className="landing-page__inner">
					<p className="logo">cm.</p>
					<h1>
						Hey there. I’m giving my site a facelift.
						<br />
						Please come visit another time.
					</h1>
					<div className="social-icons">
						<a href="mailto:charliematina@gmail.com" rel="noopener noreferrer"><Mail /></a>
						<a href="https://www.linkedin.com/in/charlie-matina-9bb3b1109/" target="_blank" rel="noopener noreferrer"><Linkedin /></a>
						<a href="https://www.instagram.com/charliematina/?hl=en" target="_blank" rel="noopener noreferrer"><Instagram /></a>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;