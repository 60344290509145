import React from 'react';
import LandingPage from './pages/LandingPage';

function App() {
  return <div>
      <LandingPage />
  </div>;
}

export default App;
